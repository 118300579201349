<template lang="pug">
  div(class="block-tag-delete")
    div(class="block-tag-delete__image")
      v-img(:src="require('~/assets/images/warning.png')" max-width="80")
    div(class="block-tag-delete__content")
      h2(class="title-32px mb-2") {{ $t(title) }}
      div(class="text-14px mb-8") {{ $t(subtitle) }}
    div(class="block-tag-delete__actions")
      v-btn(
        height="48"
        @click="closeModal"
      ) {{ $t('Cancel') }}
      v-btn(
        class="secondary-button error-btn"
        @click="submit"
        :loading="loading"
      ) {{ $t('Delete') }}
</template>

<script>
import Tag from '~/modules/tags/models/Tag'

export default {
  name: 'BlockTagDelete',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    tag: {
      type: Object,
      default: null
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        let shouldRedirect = false
        if (this.$route.params.id === this.tag.id) {
          shouldRedirect = true
        }
        await Tag.api().del(this.tag)
        if (shouldRedirect) {
          this.$router.push({ name: 'documents' })
        } else {
          await this.tableRequest()
        }
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-tag-delete {
  padding: 16px 0;
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
</style>
