<template lang="pug">
  div(class="documents-signed-success")
    div(class="documents-signed-success__image")
      v-img(:src="require('~/assets/images/check-green.png')" max-width="80")
    div(class="documents-signed-success__content")
      h3(class="documents-signed-success__title") {{ $t(title) }}
      p(class="documents-signed-success__text") {{ $t(text) }}
    div(class="documents-signed-success__actions")
      v-row(v-if="!isInformationDialog")
        v-col(cols="12")
          v-btn(class="main-button w-100 mb-4" @click="submit") {{ $t(buttonText) }}
          v-btn(@click="closeModal(false)" class="w-100" height="48") {{ $t('Skip') }}
      v-row(v-else)
        v-col(cols="12" class="py-0")
          v-btn(class="main-button w-100" @click="closeModal") {{ $t('Understand') }}
</template>

<script>
export default {
  name: 'BlockDocumentsSuccess',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    onSubmit: {
      type: Function,
      default: null
    },
    isInformationDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submit () {
      if (typeof this.onSubmit === 'function') {
        this.onSubmit()
      }
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.documents-signed-success {
  padding: 28px 0;
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__title {
    margin-bottom: 12px;

    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: $black;
  }
  &__text {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
  &__btn {
    flex-grow: 1;
  }
}
</style>
