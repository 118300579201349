<template lang="pug">
  v-row(v-if="permittedItems.length" class=" t-orm-menu-actions")
    v-col(cols="12" class="py-0 d-flex justify-center")
      v-menu(left bottom content-class="action-icons" nudge-left="30")
        template(v-slot:activator="{ on }")
          v-btn(icon v-on="on")
            v-icon mdi-dots-horizontal

        v-list(dense class="py-0")
          template(v-for="(item, i) in permittedItems")
            v-list-item(v-if="isVisible(item)"
                       v-bind="attrs(item)"
                       :key="i"
                       @click="itemCall(item)")
              v-list-item-icon(v-if="item.icon")
                component(:is="item.icon.type"
                  v-bind="item.icon.attrs ? item.icon.attrs : {}"
                  :name="iconName(item)"
                  :class="iconClasses(item)"
                  :text="iconName(item)")

              v-list-item-content
                v-list-item-title {{ _.upperFirst($t(text(item))) }}

            hr(v-if="item.length > 1" class="align-self mx-auto")
</template>

<script>
import { VIcon } from 'vuetify/lib'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    VIcon,
    EFlatIcon,
    ESvgIcon
  },
  props: {
    target: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    permittedItems () {
      return this.items.filter(i => this.isVisible(i))
    }
  },
  methods: {
    isVisible (item) {
      const rules = [
        this._.isFunction(item.isPermitted) ? item.isPermitted(this.target) : true,
        this._.isFunction(item.visible) ? item.visible(this.target, this) : true,
        !this.isDisabled(item)
      ]

      return this._.compact(rules).length === rules.length
    },

    isDisabled (item) {
      const rules = [
        this._.isFunction(item.disabled) ? item.disabled(this.target) : false
      ]

      return this._.compact(rules).length === rules.length
    },

    attrs (item) {
      const attrs = Object.assign({}, item.attrs)

      if (this.isDisabled(item)) {
        delete attrs.color
      }

      return attrs
    },

    text (item) {
      return this._.isFunction(item.text) ? item.text(this.target) : item.text
    },
    iconName (item) {
      return this._.isFunction(item.icon.text) ? item.icon.text(this.target) : item.icon.text
    },

    iconClasses (item) {
      const color = this._.get(this.attrs(item), 'color', null)

      return color ? `${color}--text` : null
    },
    itemCall (item) {
      this._.isFunction(item.call) && item.call(this.target, item.data, this)
    }
  }
}
</script>

<style lang="scss" scoped>
  .t-orm-menu-actions {
    //
    padding: 4px 0;
  }
  .action-icons.v-menu {
    &__content {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      overflow: visible;
      contain: none;
      &::before, &::after {
        width: 0;
        height: 0;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        font-size: 0;
        line-height: 0;
        content: " ";
        position: absolute;
        top: 15px;
      }
      &::before {
        border-left: 5px solid rgba(0, 0, 0, 0.12);
        right: -6px;
      }
      &::after {
        border-left: 5px solid white;
        right: -5px;
      }
      .v-list {
        .v-list-item {
          padding: 0 10px;
        }
        hr {
          width: 90%;
          border: none;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.12);
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
</style>
