<template lang="pug">
    div(class="block-documents-download pb-5 px-2")
        h1(class="block-documents-download__title") {{ $t('Download') }}
        div(class="block-documents-download__actions")
            v-btn(
                :loading="buttonLoadings.original"
                class="block-documents-download__btn"
                @click="handleDownload('original')"
            )
              | {{ $t('Original document') }}
              e-svg-icon(name="file-attach")
            v-btn(
                :loading="buttonLoadings.signed"
                class="block-documents-download__btn"
                @click="handleDownload('signed')"
            )
              | {{ xsDevice ? $t('Signed document') : $t('Signed document (format p7s)') }}
              e-svg-icon(name="key-2")
            v-btn(
                :loading="buttonLoadings.protocol"
                class="block-documents-download__btn"
                @click="handleDownload('protocol')"
            )
              | {{ xsDevice ? $t('For print') : $t('For print (with signature protocol)') }}
              e-svg-icon(name="printer-2")
</template>

<script>
import Download from 'js-file-download'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Document from '~/modules/documents/models/Document'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockDocumentsDownload',
  components: { ESvgIcon },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    documentId: {
      type: String,
      default: null
    },
    documentIds: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      buttonLoadings: {
        original: false,
        signed: false,
        protocol: false
      }
    }
  },
  methods: {
    getFilenameFromContentDisposition (header) {
      const dispositionRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/gi
      let matches
      let lastFilename

      while ((matches = dispositionRegex.exec(header)) !== null) {
        lastFilename = matches[1].replace(/['"]/g, '')
      }

      // Remove "utf-8''" prefix more flexibly
      lastFilename = lastFilename.replace(/(?:utf-8'')?(.*)/, '$1')

      // Decode URL-encoded characters
      lastFilename = decodeURIComponent(lastFilename)

      return lastFilename || null
    },
    async handleDownload (type) {
      const documentsIdsLength = this._.get(this.documentIds, 'length')
      if (this.documentId || documentsIdsLength === 1) {
        await this.documentDownload(type)
        return
      }
      if (this.documentIds) {
        await this.listDownload(type)
      }
    },
    async documentDownload (type) {
      try {
        this.buttonLoadings[type] = true
        const documentId = this.documentId || this._.get(this.documentIds, '[0]')
        const request = await Document.api().download(documentId, {
          type: 'attachment',
          file: type
        })
        const contentDisposition = this._.get(request, 'response.headers.content-disposition')
        const fileName = this.getFilenameFromContentDisposition(contentDisposition)
        const fullFileName = fileName.split('utf-8')[1]
        Download(this._.get(request, 'response.data'), fullFileName)
      } catch (e) {
        const error = JSON.parse(await e.response.data.text())
        this.$handlers.error(error, this)
      } finally {
        this.buttonLoadings[type] = false
      }
    },
    async listDownload (type) {
      try {
        this.buttonLoadings[type] = true
        const payload = {
          type: 'export',
          documentIds: this.documentIds,
          exportType: type
        }
        const taskId = this._.get(await Document.api().downloadDocuments(payload), 'response.data.id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          const request = await Document.api().downloadArchive(taskId)
          Download(this._.get(request, 'response.data'), 'archive.zip')
        }
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.buttonLoadings[type] = false
      }
    },
    checkStatus (id) {
      const resolveStatus = 'complete'
      const request = new IntervalRequest(() => Document.api().checkStatus(id), {
        interval: 1e3,
        maxDelay: 12e4 // ~2 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    }
  }
}
</script>

<style scoped lang="scss">
.block-documents-download {

  &__title {
    padding-bottom: 32px;
    padding-top: 24px;

    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 32px;

      padding-bottom: 24px;
      padding-top: 12px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__btn {
    width: 100%;
    background: #F8F8F8 !important;
    border-radius: 16px !important;
    height: 54px !important;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
