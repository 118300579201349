<template lang="pug">
  v-form(class="organizations-users-edit")
    h2(class="title-32px mb-2") {{ $t('Change user role') }}
    p(class="text-14px mb-8") {{ $t('You are going to change the role of the selected user in the system. The selected role will determine the level of access and user capabilities in the system') }}
    ValidationObserver(ref="form")
      ValidationProvider(:name="$t('Email')" rules="required|email" v-slot="{ errors }" mode="lazy")
        v-text-field(
          v-model="dataForm.email"
          :label="$t('Email')"
          :error-messages="errors"
          readonly
          filled
        )
      ValidationProvider(:name="$t('Role in system')" rules="required" v-slot="{ errors }" mode="lazy")
        v-select(
          v-model="dataForm.role"
          :items="userRoles"
          :label="$t('Role in system')"
          :error-messages="errors"
          :menuProps="{ bottom: true, offsetY: true }"
          filled
        )
    div(class="organizations-users-edit__actions")
      v-btn(
        height="48"
        @click="closeModal(false)"
      ) {{ $t('Cancel') }}
      v-btn(
        :loading="isLoading"
        class="main-button"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import OrganizationUsers from '~/modules/organizations/models/OrganizationUsers'

export default {
  name: 'FormOrganizationsUsersEdit',
  components: {
    TOrmFields,
    ValidationProvider
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      dataForm: {
        email: '',
        role: ''
      }
    }
  },
  computed: {
    userRoles () {
      return OrganizationUsers.userRoles.filter(item => item.value !== OrganizationUsers.ROLES.OWNER)
    }
  },
  created () {
    this.dataForm.email = this.user.email
    this.dataForm.role = this.user.role
  },
  methods: {
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      if (this.dataForm.role === this.user.role) {
        this.closeModal(false)
        return
      }
      try {
        this.isLoading = true
        await OrganizationUsers.api().update({ $id: this.user.id }, this.dataForm)
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.organizations-users-edit {
  padding: 24px 0 28px 0;
  text-align: left;

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
</style>
