const apiPrefix = '/api/v1'
const routes = {
  // TODO: create dynamic generation of routes.
  login: _ => apiPrefix + '/login',
  auth: {
    refresh_token: _ => apiPrefix + '/token/refresh',
    get_code: _ => [apiPrefix, '/auth', '/get-code'].join('').trim(),
    get_token: _ => [apiPrefix, '/auth', '/get-token'].join('').trim()
  },
  users: {
    list: _ => apiPrefix + '/users',
    authorizeInSfs: _ => ['/users', '/', 'tax-refresh-token'].join('').trim(),
    concrete: id => [apiPrefix, '/users', '/', id].join('').trim(),
    changePassword: _ => [apiPrefix, '/user', '/change-password'].join('').trim(),
    profile: _ => [apiPrefix, '/user', '/profile'].join('').trim(),
    getAuthToken: id => [apiPrefix, '/users', '/', id, '/get-auth'].join('').trim(),
    billing: _ => [apiPrefix, '/user', '/billing'].join('').trim(),
    currentOrganization: _ => [apiPrefix, '/user', '/current-organization'].join('').trim()
  },
  currentuser: {
    concrete: id => [apiPrefix, '/users', '/', id].join('').trim()
  },
  document: {
    list: _ => apiPrefix + '/documents',
    concrete: id => [apiPrefix, '/documents', '/', id].join('').trim(),
    sign: id => [apiPrefix, '/documents', '/', id, '/sign'].join('').trim(),
    send: id => [apiPrefix, '/documents', '/', id, '/send'].join('').trim(),
    download: id => [apiPrefix, '/documents', '/', id, '/download'].join('').trim(),
    participants: id => [apiPrefix, '/documents', '/', id, '/participants'].join('').trim(),
    signatures: id => [apiPrefix, '/documents', '/', id, '/signatures'].join('').trim(),
    approve: id => [apiPrefix, '/documents', '/', id, '/approve'].join('').trim(),
    reject: id => [apiPrefix, '/documents', '/', id, '/reject'].join('').trim(),
    toArchive: id => [apiPrefix, '/documents', '/', id, '/to-archive'].join('').trim(),
    fromArchive: id => [apiPrefix, '/documents', '/', id, '/from-archive'].join('').trim(),
    documentLink: id => [apiPrefix, '/documents', '/', id, '/links'].join('').trim(),
    downloadDocuments: _ => [apiPrefix, '/downloads'].join('').trim(),
    downloadStatus: id => [apiPrefix, '/downloads', '/', id, '/status'].join('').trim(),
    downloadArchive: id => [apiPrefix, '/downloads', '/', id, '/download'].join('').trim(),
    tags: id => [apiPrefix, '/documents', '/', id, '/', 'tags'].join('').trim(),
    concreteTag: (id, tagId) => [apiPrefix, '/documents', '/', id, '/', 'tags', '/', tagId].join('').trim(),
    attachTags: _ => [apiPrefix, '/documents', '/attach-tags'].join('').trim()
  },
  organization: {
    list: _ => apiPrefix + '/organizations',
    concrete: id => [apiPrefix, '/organizations', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    },
    synchronize: id => [apiPrefix, '/organizations', '/', id, '/synchronization'].join('').trim(),
    cashRegisters: id => ['/organizations', '/', id, '/cash-registers'].join('').trim(),
    sendTaxAuthData: _ => ['/tax', '/update-token'].join(''),
    exists: edrpou => [apiPrefix, '/organizations', '/', edrpou, '/exists'].join('').trim()
  },
  organizationusers: {
    list: _ => apiPrefix + '/organization-users',
    concrete: id => [apiPrefix, '/organization-users', '/', id].join('').trim(),
    accept: id => [apiPrefix, '/organization-users', '/', id, '/accept'].join('').trim(),
    users: id => [apiPrefix, '/organizations', '/', id, '/users'].join('').trim(),
    notify: id => [apiPrefix, '/organization-users', '/', id, '/notify'].join('').trim()
  },
  participant: {
    concrete: id => [apiPrefix, '/participants', '/', id].join('').trim()
  },
  edsKey: {
    check: _ => '/eds-keys/check'
  },
  get organizationsearch () {
    return this.organization
  },
  shortcode: {
    concrete: id => [apiPrefix, '/shortcodes', '/', id].join('').trim()
  },
  tag: {
    list: _ => apiPrefix + '/tags',
    concrete: id => [apiPrefix, '/tags', '/', id].join('').trim()
  },
  accesstoken: {
    list: _ => apiPrefix + '/access-tokens',
    concrete: id => [apiPrefix, '/access-tokens', '/', id].join('').trim()
  },
  bulksignature: {
    concrete: id => [apiPrefix, '/bulk-signatures', '/', id].join('').trim()
  }
}

export default routes
