<template lang="pug">
  div(class="form-tag")
    h2(class="form-tag__title") {{ title }}
    div(v-if="!isEdit" class="form-tag__text") {{ $t('Help organize documents by providing a convenient way to quickly find, sort and use them') }}
    v-form(@submit.prevent.stop="onSubmit")
      ValidationObserver(ref="form" slim)
        ValidationProvider(
          rules="required"
          vid="name"
          :name="wrapQuotesProviderName($t('Tag name'))"
          v-slot="{ errors }"
          mode="lazy"
        )
          v-text-field(
            v-model="form.name"
            filled
            :error-messages="errors"
            :label="$t('Enter name of new tag')"
          )
        div(class="form-tag__label mb-4") {{ $t('Choose tag color') }}
        ValidationProvider(
          rules="required"
          vid="color"
          :name="wrapQuotesProviderName($t('Tag color'))"
          v-slot="{ errors }"
          mode="lazy"
        )
          v-radio-group(
            v-model="form.color"
            row
            :error-messages="errors"
            class="form-tag__radio-group"
          )
            v-btn(
              v-for="colorItem in colors"
              :key="colorItem.color"
              icon
              class="form-tag__color-picker"
              @click="selectColor(colorItem.color)"
            )
              div(
                class="form-tag__color"
                :style="{ backgroundColor: colorItem.color }"
              )
                e-svg-icon(size="xs" v-if="colorItem.color === form.color") check-white
        div(class="form-tag__actions")
          v-btn(
            @click="closeModal"
            height="48"
          ) {{ $t('Cancel') }}
          v-btn(
            type="submit"
            class="main-button"
            :loading="loading"
          ) {{ buttonText }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Tag from '~/modules/tags/models/Tag'
import Document from '~/modules/documents/models/Document'
import validate from '~/mixins/validation/validate'

export default {
  name: 'FormTag',
  components: {
    ValidationProvider,
    ESvgIcon
  },
  mixins: [validate],
  props: {
    item: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    document: {
      type: Object,
      default: null
    },
    documents: {
      type: Array,
      default: null
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      form: {
        name: null,
        color: null
      }
    }
  },
  computed: {
    model () {
      return Tag
    },
    documentModel () {
      return Document
    },
    colors () {
      return [
        {
          color: '#FF1744'
        },
        {
          color: '#FFA04C'
        },
        {
          color: '#edf111'
        },
        {
          color: '#049541'
        },
        {
          color: '#514FFF'
        },
        {
          color: '#8F257D'
        },
        {
          color: '#121212'
        }
      ]
    },
    title () {
      return this.isEdit ? this.$t('Tag edit') : this.$t('New tag')
    },
    buttonText () {
      return this.isEdit ? this.$t('Save') : this.$t('Create')
    }
  },
  created () {
    if (this.item) {
      this.form.color = this.item.color
      this.form.name = this.item.name
    }
  },
  methods: {
    selectColor (color) {
      this.form.color = color
    },
    async onSubmit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        if (this.isEdit) {
          await this.model.api().update(this.item, this.form)
          await this.tableRequest()
        } else {
          const { id } = this._.get(await this.model.api().create(this.form), 'response.data')
          const payload = {
            tagIds: [id]
          }
          if (this.document) {
            await this.documentModel.api().addTags(this.document.id, payload)
            await this.documentModel.api().read(this.document.id)
          }
          if (this.documents && this.documents.length) {
            payload.documentIds = this._.map(this.documents, item => item.id)
            await this.documentModel.api().attachTags(payload)
            await this.tableRequest()
          }
        }
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-tag {
  text-align: left;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 24px 12px;
  }

  &__title {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;
  }

  &__text {
    color: rgba(18, 18, 18, 0.64);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    margin-bottom: 32px;
  }
  &__label {
    color: #161B25;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
  &__radio-group {
    &::v-deep {
      .v-input--radio-group__input {
        justify-content: space-between;
      }
    }
  }
  &__color-picker {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__color {
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
  }
  &__actions {
    margin-top: 32px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }
}
</style>
