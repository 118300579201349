import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export default class Organization extends ChainInheritance(Administration, [
  PersistAble
]) {
  static entity = 'organization'
  static paginated = true

  static personTypes = [
    {
      text: 'Фізична особа підприємець',
      value: 'individual'
    }, {
      text: 'Юридична особа',
      value: 'juridical'
    }
  ]

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      edrpou: this.attr(null),
      currentUserRole: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Organization',
    multy: 'Organizations',
    dialog: 'organization'
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.sendTaxAuthData = function (payload) {
        return this.post(Model.$routes.organization.sendTaxAuthData(), payload, { save: false })
      }

      configActions.exists = function (edrpou) {
        return this.get(Model.$routes.organization.exists(edrpou), { save: false })
      }

      return configActions
    }
  }
}
