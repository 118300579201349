<template lang="pug">
  div
    header(class="header-mobile")
      div(class="d-flex align-center")
        e-button-burger(class="mr-5" @click="drawer = !drawer" :active="false")
        v-img(
          max-width="152"
          :src="require('~/assets/images/logo/logo-black.svg')"
        )
      block-profile-menu
    v-navigation-drawer(
      v-model="drawer"
      app
      disable-resize-watcher
      width="100%"
      left
      class="drawer-mobile"
      :style="{ zIndex: 4 }"
      color="#2A292F"
    )
      v-btn(icon @click="drawer = !drawer")
        e-svg-icon(name="cross-close" size="lg")
      v-list
        template(v-for="(prependLink, index) in items")
          v-list-item(v-if="prependLink.visible" :key="index")
            nuxt-link(:to="prependLink.href" class="d-flex align-center link" exact)
              v-list-item-title(class="drawer-mobile__link-text") {{ $t(prependLink.text) }}
</template>

<script>
import { menuItems } from '~/const/menuItems'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EButtonBurger from '~/components/elements/buttons/e-button-burger'
import BlockProfileMenu from '~/modules/profile/views/components/block-profile-menu'

export default {
  components: {
    BlockProfileMenu,
    ESvgIcon,
    EButtonBurger
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    items () {
      return menuItems
    }
  },
  methods: {
    goToProfile () {
      this.$router.push('/profile')
    }
  }
}
</script>

<style scoped lang="scss">
.header-mobile {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  background: #ffffff;

  &__add-btn {
    width: 40px;
    height: 40px;
  }

  &__menu {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.12);
  }

  &__action {
    &-title {
      color: #000;
      font-size: 14px !important;
      font-weight: 300;
      line-height: 24px;
    }

    &:not(:last-child) {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.03);
    }
  }
}
.drawer-mobile {
  &__link {
    &-text {
      color: #fff;
    }
  }
}
</style>
