<template lang="pug">
  div(class="profile-api-keys-form")
    div(v-if="showCopyBlock" class="profile-api-keys-form__image")
      v-img(:src="require('~/assets/images/key.png')" max-width="80")
    h2(class="title-32px" :class="[showCopyBlock ? 'mb-2' : 'mb-8']") {{ title }}
    div(v-if="showCopyBlock" class="text-16px mb-8") {{ $t('Managing key, you set an integration') }}
    block-profile-api-keys-copy(
      v-if="showCopyBlock"
      :api-key="apiKey"
      @close="closeModal"
    )
    v-form(
      v-else
      @submit.prevent.stop="handleSubmit"
    )
      ValidationObserver(
        ref="form"
        slim
      )
        ValidationProvider(vid="title" v-slot="{ errors }" :name="wrapQuotesProviderName($t('Key name'))" mode="lazy")
          v-text-field(
            v-model="form.title"
            filled
            :error-messages="errors"
            :label="$t('Enter key name (not required)')"
          )
        div(class="profile-api-keys-form__actions")
          v-btn(
            height="48"
            @click="closeModal"
          ) {{ $t('Cancel') }}
          v-btn(
            type="submit"
            class="main-button"
            :loading="loading"
          ) {{ buttonText }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import BlockProfileApiKeysCopy from '~/modules/profile/views/components/block-profile-api-keys-copy'
import validate from '~/mixins/validation/validate'
import AccessToken from '~/modules/profile/models/AccessToken'

export default {
  name: 'BlockProfileApiKeysForm',
  components: {
    BlockProfileApiKeysCopy,
    ValidationProvider
  },
  mixins: [validate],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      form: {
        title: null
      },
      showCopyBlock: false,
      apiKey: null
    }
  },
  computed: {
    title () {
      if (this.isEdit) {
        return this.$t('Title edit')
      } else if (this.showCopyBlock) {
        return this.$t('API key created')
      } else {
        return this.$t('New key')
      }
    },
    buttonText () {
      if (this.isEdit) {
        return this.$t('Save')
      } else {
        return this.$t('Create key')
      }
    }
  },
  created () {
    if (this.item) {
      this.form.title = this.item.title
    }
  },
  methods: {
    handleSubmit () {
      if (this.isEdit) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }
    },
    async handleCreate () {
      try {
        this.loading = true
        const key = this._.get(await AccessToken.api().create(this.form), 'response.data')
        this.apiKey = this._.get(key, 'accessToken')
        this.showCopyBlock = true
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async handleUpdate () {
      try {
        this.loading = true
        await AccessToken.api().update(this.item, this.form)
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.profile-api-keys-form {
  text-align: left;
  padding: 16px 0;

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
</style>
