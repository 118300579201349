<template lang="pug">
  div(class="organizations-success")
    div(class="organizations-success__image")
      v-img(:src="require('~/assets/images/check-green.png')" max-width="80")
    div(class="organizations-success__content")
      h3(class="organizations-success__title") {{ $t(title) }}
      p(class="organizations-success__text") {{ $t(text) }}
    div(class="organizations-success__actions")
      v-btn(@click="closeModal" class="main-button w-100" height="48") {{ $t('Ok') }}
</template>

<script>
export default {
  name: 'BlockOrganizationsSuccess',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.organizations-success {
  padding: 16px 0;
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__title {
    margin-bottom: 12px;

    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: $black;
  }
  &__text {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
  &__btn {
    flex-grow: 1;
  }
}
</style>
