<template lang="pug">
  v-app
    v-content
      v-container(fluid fill-height class="default pa-0 background align-start")
        nuxt
    notification
    dialogs(ref="dialogs")
    snackbars
</template>

<script>
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import snackbars from '~/modules/snackbar/views/components/snackbars'

export default {
  components: {
    notification,
    dialogs,
    snackbars
  }
}
</script>
